<template>
    <zw-sidebar @shown="shown"
                :title="payload.id ? $t('common.form.title.editContactPerson')   + ' (' + payload.id + ')' : $t('common.form.title.createContactPerson')"
    >
        <ValidationObserver tag="div" ref="observer">
            <b-overlay :show="loading" no-wrap></b-overlay>
            <div v-if="!loading">
                <b-row>
                    <b-col sm="3">
                        <zw-select-group v-model="form.addressType"
                                         :options="getAddressTypes()"
                                         name="addressType"
                                         :label-prefix="labelPrefix"
                                         icon="map-marked-alt"
                                         validate="required"
                        ></zw-select-group>
                    </b-col>
                    <b-col sm="3">
                        <zw-switch-group v-model="form.default"
                                         name="default"
                                         :label-prefix="labelPrefix"
                        ></zw-switch-group>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col sm="3">
                        <zw-select-group v-model="form.salutation"
                                         :options="getSalutations()"
                                         name="salutation"
                                         :label-prefix="labelPrefix"
                                         icon="address-card"
                        ></zw-select-group>
                    </b-col>

                    <b-col sm="3">
                        <zw-input-group v-model="form.firstname"
                                        name="firstname"
                                        icon="address-card"
                                        :label-prefix="labelPrefix"
                                        :form="form"
                        ></zw-input-group>
                    </b-col>

                    <b-col sm="3">
                        <zw-input-group v-model="form.lastname"
                                        :label-prefix="labelPrefix"
                                        name="lastname"
                                        icon="address-card"
                                        :form="form"
                        ></zw-input-group>
                    </b-col>

                    <b-col sm="3">
                        <zw-input-group v-model="form.position"
                                        icon="address-card"
                                        :label-prefix="labelPrefix"
                                        name="position"
                        ></zw-input-group>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col sm="3">
                        <zw-input-group v-model="form.street"
                                        name="street"
                                        :label-prefix="labelPrefix"
                                        icon="map-marked-alt"
                                        :validate="{ required: true }"
                        ></zw-input-group>
                    </b-col>
                    <b-col sm="3">
                        <zw-input-group v-model="form.postCode"
                                        icon="map-marked-alt"
                                        name="postCode"
                                        :label-prefix="labelPrefix"
                                        :validate="{ required: true }"
                        ></zw-input-group>
                    </b-col>
                    <b-col sm="3">
                        <zw-input-group v-model="form.city"
                                        name="city"
                                        :label-prefix="labelPrefix"
                                        icon="map-marked-alt"
                                        :validate="{ required: true }"
                        ></zw-input-group>
                    </b-col>
                    <b-col sm="3">
                        <zw-select-group v-model="form.countryCode"
                                         :options="getCountries()"
                                         name="country"
                                         :label-prefix="labelPrefix"
                                         icon="globe-europe"
                                         text-field="name"
                                         value-field="iso2"
                                         rules="required"
                        ></zw-select-group>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col sm="3">
                        <zw-input-group v-model="form.email"
                                        icon="at"
                                        name="email"
                                        :label-prefix="labelPrefix"
                        ></zw-input-group>
                    </b-col>
                    <b-col sm="3">
                        <zw-phone-group v-model="form.phone"
                                        name="phone"
                                        :label-prefix="labelPrefix"
                                        icon="phone"
                        ></zw-phone-group>
                    </b-col>
                    <b-col sm="3">
                        <zw-phone-group v-model="form.mobile"
                                        icon="mobile-alt"
                                        name="mobile"
                                        :label-prefix="labelPrefix"
                        ></zw-phone-group>
                    </b-col>
                    <b-col sm="3">
                        <zw-input-group v-model="form.fax"
                                        icon="fax"
                                        name="fax"
                                        :label-prefix="labelPrefix"
                        ></zw-input-group>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col sm="3">
                        <zw-input-group v-model="form.website"
                                        icon="globe"
                                        name="website"
                                        :label-prefix="labelPrefix"
                        ></zw-input-group>
                    </b-col>
                    <b-col sm="3">
                        <zw-input-group v-model="form.linkedIn"
                                        name="linkedIn"
                                        :label-prefix="labelPrefix"
                                        :icon="['fab', 'linkedin']"
                        ></zw-input-group>
                    </b-col>
                    <b-col sm="3">
                        <zw-input-group v-model="form.facebook"
                                        name="facebook"
                                        :label-prefix="labelPrefix"
                                        :icon="['fab', 'facebook']"
                        ></zw-input-group>
                    </b-col>
                    <b-col sm="3">
                        <zw-input-group v-model="form.xing"
                                        name="xing"
                                        :label-prefix="labelPrefix"
                                        :icon="['fab', 'xing']"
                        ></zw-input-group>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col sm="3">
                        <zw-date-group name="birthDate"
                                       icon="birthday-cake"
                                       :label-prefix="labelPrefix"
                                       v-model="form.birthDate"
                        ></zw-date-group>
                    </b-col>
                    <b-col sm="3">
                        <zw-input-group v-model="form.care_of_address"
                                        name="care_of_address"
                                        :label-prefix="labelPrefix"
                                        icon="map-marked-alt"
                        ></zw-input-group>
                    </b-col>
                    <b-col sm="3">
                        <zw-input-group v-model="form.additional"
                                        name="additional"
                                        :label-prefix="labelPrefix"
                                        icon="map-marked-alt"
                        ></zw-input-group>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col class="text-sm-left text-xs-center" sm="6">
                        <b-row>
                            <b-col sm="6">
                                <b-button @click="copyFromBase" block variant="warning">
                                    {{ $t('common.button.copy_from_base') }}
                                </b-button>
                            </b-col>
                        </b-row>
                    </b-col>
                    <b-col sm="6" class="text-sm-right text-xs-center">
                        <b-row align-h="end">
                            <b-col sm="6">
                                <b-button block @click="onSubmit" variant="primary">
                                    {{ $t('common.button.save') }}
                                </b-button>
                            </b-col>
                        </b-row>
                    </b-col>
                </b-row>
            </div>
        </ValidationObserver>
    </zw-sidebar>
</template>

<script>
import {mapGetters} from 'vuex'
import ValidateMixin from './../../mixins/validate'
import commonSave from '@/bundles/erika_common_bundle/mixins/common-save'

export default {
    name: 'contact-person-form',
    mixins: [ValidateMixin, commonSave],
    data() {
        return {
            loading: true,
            payload: {},
            form: {},
            labelPrefix: 'customer.label.',
        }
    },
    methods: {
        ...mapGetters('ContactPerson', ['getContactPerson']),
        ...mapGetters('Customer', ['getCustomer']),
        ...mapGetters('CommonData', ['getSalutations', 'getCountries', 'getAddressTypes']),
        onSubmit() {
            this.$refs['observer'].validate().then(valid => {
                if (valid) {
                    this.loading = true
                    this.$store.dispatch('ContactPerson/saveContactPerson', this.form)
                        .then((response) => {
                            this.commonAfterSave(response)
                        })
                        .finally(() => {
                            this.loading = false
                        })
                } else {
                    this.showValidationError()
                }
            })
        },
        copyFromBase() {
            this.$store.dispatch('Customer/fetchCustomer', this.payload.customerId).then(() => {
                let customer = this.getCustomer()
                delete customer.id;
                this.form = {...this.form, ...customer}
            })
        },
        shown() {
            this.$store.dispatch('ContactPerson/fetchContactPerson', {
                id: this.payload.id,
                customerId: this.payload.customerId
            })
                .then(() => {
                    this.form = this.getContactPerson()
                    this.loading = false;
                })
                .finally(() => {
                    this.loading = false
                })
        },
    }
}
</script>